import { bbox, booleanPointInPolygon, helpers } from "@turf/turf";
import { GoogleApiWrapper, Map, Polygon } from 'google-maps-react';
import React, { useEffect, useState } from "react";
import Loading from "../../../components/loading";
import api from "../../../services/api";
import features from "./features.json";
import "./style.css";

const Maps = ({ google, hours, sections, range }) => {

    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [shape, setShape] = useState([]);
    const [positions, setPositions] = useState([]);
    const [mapInstance, setMapInstance] = useState();
    const [data, setData] = useState([]);
    const [headMap, setHeadMap] = useState();
    const _center = process.env.REACT_APP_CENTER_MAP.split(',');
    const center = {lat: _center[0].trim(),lng: _center[1].trim()};
    const zoom = process.env.REACT_APP_CENTER_MAP_ZOOM;

    const fetchShape = async () => {
        try {
            setError(undefined);
            let { data } = await api.get(`/mobility/statisticalSession/all`);
            setShape(data || [])
        } catch (error) {
            setError(error?.response?.data?.message || "unknown error");
        }
    }

    const fetchData = async () => {
        if (range) {
            try {
                let { data } = await api.post(`/mobility/statifyRegister/statistic/sample`,
                    {
                        sections: sections || [],
                        hours: hours || [],
                        range: range.id
                    },
                    {
                        params: {
                            sample: 20000
                        }
                    }
                );
                setData(data);
            } catch (error) {
                setError(error?.response?.data?.message || "unknown error");
            }
        }
    };

    const randomPointInPoly = function (polygon) {
        let _bbox = bbox(polygon);

        let west = _bbox[0];
        let south = _bbox[1];
        let east = _bbox[2];
        let north = _bbox[3];

        let x_min = parseFloat(east);
        let x_max = parseFloat(west);
        let y_min = parseFloat(south);
        let y_max = parseFloat(north);

        let lat = y_min + (Math.random() * (y_max - y_min));
        let lng = x_min + (Math.random() * (x_max - x_min));

        let point = helpers.point([lng, lat]);
        let inside = booleanPointInPolygon(point, polygon);

        if (inside) {
            return point
        } else {
            return randomPointInPoly(polygon)
        }
    }

    const generatePoints = (seccao, intensity = 1) => {
        let codSeccao = seccao.code.includes("SC") ? seccao.code.replace("SC", "") : seccao.code;
        let seccoes = shape.filter((element) => element.code.includes(codSeccao));
        let result = [];
        seccoes.forEach((seccaoSelected) => {
            let coordinates = seccaoSelected.geospatial.coordinates.map((ele) => [parseFloat(ele[0]), parseFloat(ele[1])]);

            let frist = coordinates[0]
            let last = coordinates[coordinates.length - 1]
            let t = frist.toString() == last.toString()
            if (!t) {
                coordinates.push(frist)
            }
            let position = randomPointInPoly(helpers.polygon([coordinates]));
            result.push({
                lat: position.geometry.coordinates[1],
                lng: position.geometry.coordinates[0],
                sccode: seccao.code,
                intensity: intensity
            });
        });
        return result;
    }

    const generateAllFakePosition = () => {
        let buffer = [];
        data.forEach((sec) => {
            let value = generatePoints(sec, sec.sampleLenghtThisSection);
            buffer = [...buffer, ...value];
        })
        return buffer;
    }

    useEffect(() => {
        setPositions(generateAllFakePosition());
    }, [data]);

    useEffect(() => {
        if (headMap) {
            headMap.setMap(null);
            headMap.setData([]);
        }
        if (!google?.maps?.visualization)
            return;
        let newHeadMap = new google.maps.visualization.HeatmapLayer({
            map: mapInstance,
            data: positions?.map((ele) => {
                return {
                    location: new google.maps.LatLng(ele.lat, ele.lng),
                    weight: ele.intensity
                };
            }),
            radius: 25
        });
        setHeadMap(newHeadMap);
    }, [positions, mapInstance]);

    const mapStyles = {
        width: '100%',
        height: '100%',
        borderRadius: "13px",
        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
    };

    useEffect(async () => {
        await fetchData();
    }, [hours, sections]);

    useEffect(async () => {
        try {
            setLoading(true);
            await fetchShape();
        } finally {
            setLoading(false);
        }
        await fetchData();
    }, []);

    return (
        <div className="analitysMobilityContent">
            <div style={{ marginLeft: "1em" }}>
                <span>
                    Mapa da analytics de Mobilidade
                </span>
            </div>
            {
                loading ?
                    <Loading />
                    :
                    <Map
                        onReady={(mapProps, map) => setMapInstance(map)}
                        google={google}
                        zoom={zoom?parseInt(zoom):12}
                        initialCenter={center}
                        center={center}
                        style={mapStyles}
                        streetViewControl={false}
                        zoomControl={false}
                        styles={features}
                    >

                        {shape?.map((element) =>
                            <Polygon
                                path={element?.geospatial?.coordinates?.map((coord) => {
                                    let a = {
                                        lat: parseFloat(coord[1]),
                                        lng: parseFloat(coord[0])
                                    }
                                    return a;
                                })}
                                options={{
                                    strokeColor: '#a7a9ac',
                                    strokeOpacity: 0.4,
                                    strokeWeight: 1,
                                    fillOpacity: 0
                                }}
                            >
                            </Polygon>)}
                    </Map>
            }
        </div>)
}

export default GoogleApiWrapper(
    (props) => ({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_TOKEN,
        libraries: ["visualization"]
    }
    ))(Maps);