import { Col, Row, Select } from "antd";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import ChartData from "./chart";
import Variaveis from "./variaveis";
import filterIcon from "../../../assets/icons/filter.svg";
import "../style.css";
import { element } from "prop-types";

const SolarProduction = () => {

    const [hourSeries, sethourSeries] = useState([]);
    const [categoriesHours, setCategoriesHours] = useState([]);
    const [monthSeries, setMonthSeries] = useState([]);
    const [categoriesMonth, setCategoriesMonth] = useState([]);
    const [yearSeries, setYearSeries] = useState([]);
    const [categoriesYear, setCategoriesYear] = useState([]);
    const [lastTime, setLastTime] = useState(moment());
    const [invSelected, setInvSelected] = useState("INV1");
    const [inversores, setInversores] = useState([]);

    const fetchInversores = () => {
        api.get("/solar/assets/find/asset").then((response) => {
            let newData = response.data.map((dt) => {
                if(dt.externalReference == "INVERSOR6"){
                    dt.externalReference = "INV6";
                }else if(dt.externalReference == "INVERSOR1"){
                    dt.externalReference = "INV1";
                }
                return dt;
            }).filter((ele)=>ele.externalReference.includes('INV'))
                .map((ele) => {
                    return {
                        label: ele.name,
                        value: ele.externalReference
                    }
                });
            setInversores(newData);
        });
    }

    const [chartAcumulated, setChartAcululated] = useState({
        serie: [],
        categorys: []
    });

    function format(value, decimal) {
        return Number(value).toLocaleString(undefined, {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
        });
    }

    const fetchHourSeries = async () => {
        let response = await api.get('/solar/sensors/group/date', {
            params: {
                name: `${invSelected}_EaT_kWh`,
                dtStart: moment().set({ h: 0, m: 0, s: 0 }).toISOString(),
                dtEnd: moment().set({ h: 23, m: 59, s: 59 }).toISOString(),
            }
        });
        sethourSeries(generateSeries(response.data, "day"))
    };


    const fetchMonthSeries = async () => {
        let response = await api.get('/solar/sensors/group/date', {
            params: {
                name: `${invSelected}_EaT_kWh`,
                dtStart: moment().set({ h: 0, m: 0, s: 0 }).subtract(11, "months").toISOString(),
                dtEnd: moment().set({ h: 23, m: 59, s: 59 }).toISOString(),
            }
        });
        setMonthSeries(generateSeries(response.data, "month"))
    };

    const fetchYearSeries = async () => {
        let response = await api.get('/solar/sensors/group/date', {
            params: {
                name: `${invSelected}_EaT_kWh`,
                dtStart: moment().set({ h: 0, m: 0, s: 0 }).subtract(5, "years").toISOString(),
                dtEnd: moment().set({ h: 23, m: 59, s: 59 }).toISOString(),
            }
        });
        setYearSeries(generateSeries(response.data, "year"))
    };

    const fetchLastProduction = async () => {
        let response = await api.get(`/solar/sensors/last/reading/sensors/${invSelected}_Pdc`);
        let _serie = [
            {
                name: `Energia produzida ${inversores?.find((el)=>el.value == invSelected)?.label}`,
                data: [...((chartAcumulated.serie[0] || {}).data || []), response.data.map(_ele => _ele.sourceValue).reduce((partialSum, a) => partialSum + a, 0)]
            }
        ]
        let _newChartAcumulated = {
            serie: _serie,
            categorys: [...chartAcumulated.categorys, moment().toLocaleString()]
        };
        let _maxLenght = 200;
        if (_newChartAcumulated.serie[0].data.length > _maxLenght) {
            _newChartAcumulated.serie[0].data = _newChartAcumulated.serie[0].data.slice(_newChartAcumulated.serie[0].data.length - _maxLenght - 1, _newChartAcumulated.serie[0].data.length)
        }
        if (_newChartAcumulated.categorys.length > _maxLenght) {
            _newChartAcumulated.categorys = _newChartAcumulated.categorys.slice(_newChartAcumulated.categorys.length - _maxLenght - 1, _newChartAcumulated.categorys.length)
        }
        setChartAcululated(_newChartAcumulated);
    }

    const generateSeries = (_data = [], type) => {
        let dates = type === 'day' ? Array.from(Array(24).keys()) : [];
        _data.forEach((element) => {
            if (type === 'year' && !dates.includes(parseInt(element.time))) dates.push(parseInt(element.time));
            else if(type === 'month' && !dates.includes(element.time)) dates.push(element.time);
        });
            
        if (type === 'day') {
            setCategoriesHours(dates)
        } else if (type === 'month') {
            setCategoriesMonth(dates)
        } else if (type === 'year') {
            setCategoriesYear(dates)
        }
        return [
            {
                name: `${inversores.find((ele)=>ele.value == invSelected)?.label}`,
                data: type !== 'month' ? dates.map((_date) => _data.find((_dt) => _dt.name.includes(invSelected) && parseInt(_dt.time) === _date)?.value || 0) : dates.map((_date) => _data.find((_dt) => _dt.name.includes(invSelected) && _dt.time === _date)?.value || 0),
            },

        ];
    };

    useEffect(async () => {
        try {
            await fetchHourSeries();
            await fetchMonthSeries();
            await fetchYearSeries();
            await fetchLastProduction();
        } finally {
            startTimeout();
        }
    }, [lastTime])

    useEffect(()=>{
        fetchInversores();
    }, []);

    useEffect(()=>{
        let _serie = [
            {
                name: "Energia produzida",
                data: []
            }
        ]
        let _newChartAcumulated = {
            serie: _serie,
            categorys: []
        };
        setChartAcululated(_newChartAcumulated);
    }, [invSelected]);

    const startTimeout = () => setTimeout(() => setLastTime(moment()), 1000);

    return (
        <Row gutter={16} style={{ marginBottom: 16 }}>
            <Col xs={24} sm={24} >
                
            </Col>
            <Col xs={24} sm={12}>

            <div className="filters">
                    <img src={filterIcon} /> Filtros
                </div>

                <div className="filters-energy">
                    <Row>
                    <Col xs={12}>
                        <div>
                        <span>
                            Inversor em análise:
                        </span>

                        <Select className="select_mobility"
                            value={invSelected}
                            showSearch
                            bordered={false}
                            defaultValue={""}
                            options={inversores}
                            onChange={(i) => setInvSelected(i)}
                            placeholder="Selecione um inversosr"
                        />
                        </div>
                    </Col>
                    </Row>
                </div>
                <Variaveis format={format} invSelected={invSelected} inversores={inversores}/>
            </Col>
            <Col xs={24} sm={12}>
                <ChartData
                    title="Produção em tempo real"
                    categories={chartAcumulated.categorys}
                    xaxis={{
                        type: 'datetime',
                        labels: {
                            formatter: function (val, index) {
                                return moment(val).format("HH:mm:ss");
                            }
                        }
                    }}
                    series={chartAcumulated.serie}
                    symbol="kWh"
                    type="line" >
                </ChartData>
                <ChartData title="Produção do dia por hora" categories={categoriesHours} xSymbol={'h'} series={hourSeries} symbol="kWh" type="line"></ChartData>
                <ChartData title="Produção por meses" categories={categoriesMonth} xSymbol={''} series={monthSeries} symbol="kWh" type="bar"></ChartData>
                <ChartData title="Produção por ano" categories={categoriesYear} xSymbol={''} series={yearSeries} symbol="kWh" type="bar"></ChartData>
            </Col>
        </Row>
    );
}

export default SolarProduction;